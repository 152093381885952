// import logo from './logo.svg';
// import './App.css';
// import {BrowserRouter, Routes, Route, createBrowserRouter} from 'react-router-dom';
import AllPosts from "./components/AllPosts.js";
import OnePost from "./components/OnePost.js";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <AllPosts/>
//   }
// ]);

// function App() {
//   return (
//     <BrowserRouter>
//       <div>
//         <Routes>
//           <Route exact path="/" element={AllPosts}/>
//           <Route element={OnePost} path="/:slug" />
//         </Routes>
//       </div>
//     </BrowserRouter>
//   );
// }

// export default App;


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AllPosts />} />
        <Route path="/:slug" element={<OnePost />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

function Home() {
  return <h1>Welcome to the home page!</h1>;
}

function About() {
  return <h1>About page</h1>;
}

export default App;