import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {sanityClient} from "../sanityClient.js";
import imageUrlBuilder from '@sanity/image-url';
import {PortableText} from '@portabletext/react';
import styled from 'styled-components';
import ReactPlayer from "react-player"


const Container = styled.div`
  background-color: #d1c7b7;
  min-height: 100vh;
  padding: 48px;
`;

const Card = styled.div`
  background-color: #c5d0b9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  position: relative;
`;

const TitleSection = styled.div`
  background-color: #fff;
  opacity: 0.75;
  border-radius: 12px;
  padding: 48px;
`;

const Title = styled.h2`
  font-family: 'cursive';
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 24px;
`;

const Author = styled.h4`
  font-family: 'cursive';
  font-size: 32px;
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

const Image = styled.img`
  // width: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 400px;
`;



const Content = styled.div`
  padding: 48px 64px;
`;


const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
    return builder.image(source)
  }

export default function OnePost() {
    const [postData, setPostData] = useState(null);
    const { slug } = useParams();
    

  
    useEffect(() => {
      sanityClient
        .fetch(
          `*[slug.current == "${slug}"]{
             title,
             slug,
             youtubeVideo,
             mainImage{
             asset->{
                _id,
                url
              }
            },
            body,
            "name": author->name,
            "authorImage": author->image
         }`
        )
        .then((data) => {setPostData(data[0]); console.log(data);})
        .catch(console.error);
    }, [slug]);
  
    if (!postData) return <div>Loading...</div>;
  
    return (
      <Container>
        
        <Card>
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center p-8">
            
              <TitleSection>
              <Link to="/" style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold', fontSize: '30px' }}>Home</Link>
                <Title>{postData.title}</Title>
                <div className="flex justify-center text-gray-800">
                  <img
                    src={urlFor(postData.authorImage).width(50).url()}
                    className="w-10 h-10 rounded-full"
                    alt="Author is Ishrat Ansari"
                  />
                  <Author>{postData.name}</Author>
                </div>
              </TitleSection>
            </div>
            <Image
              src={urlFor(postData.mainImage).url()}
              alt=""
              style={{ height: "400px" }}
            />
            
            
            <ReactPlayer
        url={postData.youtubeVideo}
      />
      
          </div>
          <Content>
            <PortableText value={postData.body} />
          </Content>
        </Card>
      </Container>
      
      // <div className="bg-gray-200 min-h-screen p-12">
      //   <div className="container shadow-lg mx-auto bg-green-100 rounded-lg">
      //     <div className="relative">
      //       <div className="absolute h-full w-full flex items-center justify-center p-8">
      //         {/* Title Section */}
      //         <div className="bg-white bg-opacity-75 rounded p-12">
      //           <h2 className="cursive text-3xl lg:text-6xl mb-4">
      //             {postData.title}
      //           </h2>
      //           <div className="flex justify-center text-gray-800">
      //             <img
      //               src={urlFor(postData.authorImage).width(50).url()}
      //               className="w-10 h-10 rounded-full"
      //               alt="Author is Ishrat Ansari"
      //             />
      //             <h4 className="cursive flex items-center pl-2 text-2xl">
      //               {postData.name}
      //             </h4>
      //           </div>
      //         </div>
      //       </div>
      //       <img
      //         className="w-full object-cover rounded-t"
      //         src={urlFor(postData.mainImage).url()}
      //         alt=""
      //         style={{ height: "400px" }}
      //       />
      //     </div>
      //     <div className="px-16 lg:px-48 py-12 lg:py-20 prose lg:prose-xl max-w-full">
      //       <PortableText
      //           value={postData.body}
      //       />
      //     </div>
      //   </div>
      // </div>
    );
  }