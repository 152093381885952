// import React from 'react';
// import { Link } from 'react-router-dom';
// import styled from 'styled-components';

// const Container = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 20px;
//   background-color: #f4f0e6;
//   color: #564e42;
// `;

// const Title = styled.h1`
//   font-size: 32px;
//   font-weight: bold;
//   margin-bottom: 20px;
//   text-align: center;
//   text-transform: uppercase;
//   letter-spacing: 2px;
//   color: #564e42;
// `;

// const PostList = styled.ul`
//   list-style: none;
//   padding: 0;
// `;

// const PostItem = styled.li`
//   margin-bottom: 20px;
// `;

// const PostLink = styled(Link)`
//   display: block;
//   color: #564e42;
//   text-decoration: none;
//   font-size: 20px;
//   font-weight: bold;
//   transition: color 0.3s ease;

//   &:hover {
//     color: #8c7e67;
//   }
// `;

// const PostDate = styled.span`
//   font-size: 14px;
//   color: #8c7e67;
// `;

// const AllPostsPage = () => {
//   const allPostsData = [
//     {
//       id: 1,
//       title: 'Blog Post 1',
//       date: '2022-01-01',
//       slug: 'blog-post-1',
//     },
//     {
//       id: 2,
//       title: 'Blog Post 2',
//       date: '2022-01-02',
//       slug: 'blog-post-2',
//     },
//     // Add more blog post data as needed
//   ];

//   return (
//     <Container>
//       <Title>All Posts</Title>
//       <PostList>
//         {allPostsData.map((post) => (
//           <PostItem key={post.id}>
//             <PostLink to={`/${post.slug}`}>
//               {post.title}
//               <PostDate>{post.date}</PostDate>
//             </PostLink>
//           </PostItem>
//         ))}
//       </PostList>
//     </Container>
//   );
// };

// export default AllPostsPage;






// src/components/AllPosts.js

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sanityClient } from "../sanityClient.js";
import "./BlogTheme.css"; // Import the modified CSS file for the nostalgic rustic theme
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  padding: 4px;
  background-color: #f5f5f5;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: #8b4513;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  // width: 100%;
  // height: 48px;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
`;

const PostContainer = styled.div`
  background-color: #f5deb3;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
`;

const PostTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #8b4513;
`;

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
        asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <Container>
      <Title>Virtual Vivaldi</Title>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {allPostsData?.map((post, index) => (
          <Link to={"/" + post.slug.current} key={post.slug.current}>
            <PostContainer>
              <Image src={post.mainImage.asset.url} alt="" />
              <div className="p-4">
                <PostTitle>{post.title}</PostTitle>
              </div>
            </PostContainer>
          </Link>
        ))}
      </div>
    </Container>
//   );
// }







// // src/components/AllPosts.js

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import {sanityClient} from "../sanityClient.js";
// import "./BlogTheme.css"; // Import the CSS file for the blog theme
// import styled from 'styled-components';

// const Container = styled.div`
//   margin: 0 auto;
//   padding: 4px;
// `;

// const Title = styled.h2`
//   font-size: 3xl;
//   font-weight: bold;
//   margin-bottom: 4px;
// `;

// const Image = styled.img`
//   width: 100%;
//   height: 48px;
//   object-fit: cover;
//   border-radius: 4px 4px 0 0;
// `;

// const PostContainer = styled.div`
//   background-color: white;
//   border-radius: 4px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   padding: 16px;
// `;

// const PostTitle = styled.h2`
//   font-size: xl;
//   font-weight: bold;
//   margin-bottom: 8px;
// `;

// export default function AllPosts() {
//   const [allPostsData, setAllPosts] = useState(null);

//   useEffect(() => {
//     sanityClient
//       .fetch(
//         `*[_type == "post"]{
//         title,
//         slug,
//         mainImage{
//         asset->{
//           _id,
//           url
//         }
//       }
//     }`
//       )
//       .then((data) => setAllPosts(data))
//       .catch(console.error);
//   }, []);

//   return (
//     <Container>
//     <Title>Virtual Vivaldi</Title>
//     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//       {allPostsData?.map((post, index) => (
//         <Link to={"/" + post.slug.current} key={post.slug.current}>
//           <PostContainer>
//             <Image src={post.mainImage.asset.url} alt="" />
//             <div className="p-4">
//               <PostTitle>{post.title}</PostTitle>
//             </div>
//           </PostContainer>
//         </Link>
//       ))}
//     </div>
//   </Container>















  //   <div className="container mx-auto px-4">
  //   <h2 className="text-3xl font-bold mb-4">Virtual Vivaldi</h2>
  //   <h3 className="text-xl font-semibold mb-4">Virtual Vivaldi</h3>
  //   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
  //     {allPostsData?.map((post, index) => (
  //       <Link to={"/" + post.slug.current} key={post.slug.current}>
  //         <div className="bg-white rounded-lg shadow-md">
  //           <img
  //             className="w-full h-48 object-cover rounded-t-lg"
  //             src={post.mainImage.asset.url}
  //             alt=""
  //           />
  //           <div className="p-4">
  //             <h2 className="text-xl font-bold mb-2">{post.title}</h2>
  //           </div>
  //         </div>
  //       </Link>
  //     ))}
  //   </div>
  // </div>
    // <div className="blog-container">
    //   <h2 className="blog-title">Virtual Vivaldi</h2>
    //   <h3 className="blog-subtitle">Virtual Vivaldi</h3>
    //   <div className="blog-grid">
    //     {allPostsData?.map((post, index) => (
    //       <Link to={"/" + post.slug.current} key={post.slug.current}>
    //         <div className="blog-post">
    //           <img
    //             className="blog-post-image"
    //             src={post.mainImage.asset.url}
    //             alt=""
    //           />
    //           <div className="blog-post-overlay">
    //             <h2 className="blog-post-title">{post.title}</h2>
    //           </div>
    //         </div>
    //       </Link>
    //     ))}
    //   </div>
    // </div>
  );
}